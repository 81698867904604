import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class RootPathInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = new URL(req.url);
    if (!!url.pathname.match(/^\/?(api)?@\//)) {
      return next.handle(
        req.clone({
          url: `${location.origin}${url.pathname.replace(/^\/?(api)?@\//, '/')}`,
        })
      );
    }
    return next.handle(req);
  }
}
