<ng-container *ngIf="isMobile$ | async; else desktopMode">
  <div
    [class]="'group-entry pos-relative m-group-entry mat-bg-3-hover ' + this.class"
    [style]="this.style"
    (click)="!this.isLoading && this.editTime(entry)"
  >
    <app-loader [isActive]="this.isLoading" [isAfterViewInit]="false" [enableCircle]="true"></app-loader>
    <div class="flex flex-row items-center justify-start space-x-3">
      <div class="flex min-w-0 flex-1 flex-col truncate">
        <span class="truncate">{{ (entry$ | async)?.name || ('time.none' | translate) }}</span>
        <div class="flex flex-row items-center space-x-1">
          <span class="mat-hint flex-shrink-0 truncate text-sm">{{
            (entry$ | async)?.project | defaultEntityTranslate: 'project' : 'project.none'
          }}</span>
          <div class="mat-bg-30 h-1 w-1 flex-shrink-0 rounded-full"></div>
          <span class="mat-hint truncate text-sm">{{ (entry$ | async)?.task?.name || ('task.none' | translate) }}</span>
        </div>
      </div>
      <div class="!ml-auto flex flex-row items-center justify-start space-x-2" (click)="$event.stopPropagation()">
        <mat-icon
          inline
          class="group-header-icons"
          [ngClass]="(entry$ | async)?.billable ? { 'mat-accent': true } : { 'mat-hint': true }"
          >attach_money</mat-icon
        >
        <div class="flex flex-shrink-0 flex-col space-x-0.5" *ngIf="entry$ | async as entry">
          <small> {{ entry.timeDiff | parseMsAsDuration }} </small>
          <small *ngIf="mode !== 'duration' && entry.start && entry.end">
            {{ entry.start | dfnsParseIso | formatDate: 'time' }} - {{ entry.end | dfnsParseIso | formatDate: 'time' }}
          </small>
        </div>
        <div class="relative flex items-center">
          <button
            mat-icon-button
            [disabled]="isLoading || group.disabled"
            [matMenuTriggerFor]="mobileMenu"
            [matMenuTriggerData]="{ $implicit: entry }"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ng-container *ngTemplateOutlet="menuNoticeBadge; context: { $implicit: entry }"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #desktopMode>
  <form [class]="'group-entry pos-relative ' + this.class" [style]="this.style" [formGroup]="group">
    <div class="flex flex-row items-center justify-start space-x-3">
      <div class="flex min-w-0 flex-auto flex-row items-center justify-start">
        <mat-form-field
          appearance="fill"
          class="mat-form-field-no-hint mat-form-field-compact mat-form-field-plain min-w-0 flex-auto"
        >
          <input
            type="text"
            matInput
            formControlName="name"
            [placeholder]="'time.add-name' | translate"
            (keydown.enter)="$event.preventDefault(); $event.stopPropagation()"
          />
          <div
            matSuffix
            class="flex h-full flex-row items-stretch justify-start space-x-2"
            *ngIf="projectView$ | async as val"
          >
            <mat-divider class="mat-divider-vertical h-5 self-center" vertical></mat-divider>
            <span
              [class.cursor-pointer]="!isLoading && !group.disabled"
              (click)="$event.preventDefault(); this.openProjectPicker($event)"
              (keyup.enter)="$event.preventDefault(); this.openProjectPicker($event)"
              matTooltip
              [matTooltipTemplate]="projectPickerTooltip"
              [matTooltipTemplateContext]="projectTooltipData$ | async"
              [matTooltipOptions]="{ delay: [500, 0] }"
              tabindex="0"
              class="flex flex-row items-center justify-start gap-1.5"
            >
              <div
                *ngIf="this.getProjectColor(val.project.id) as color"
                class="project-color-dot -mt-px"
                [style.backgroundColor]="color"
              ></div>
              <span> {{ val.project | defaultEntityTranslate: 'project' : 'project.none' | truncate: 20 }} </span>
              <div class="mat-bg-30 h-1 w-1 flex-shrink-0 rounded-full"></div>
              <span>{{ val.task?.name || ('task.none' | translate) | truncate: 25 }}</span>
            </span>
          </div>
        </mat-form-field>
      </div>
      <div class="flex flex-row items-center justify-start space-x-2.5">
        <div elementRef #tagPickerRef="nativeElement" class="entry-tags flex flex-row items-center justify-end">
          <button
            mat-icon-button
            class="mat-button-icon-sm"
            [ngClass]="{ '!cursor-default': isLoading || group.disabled }"
            (click)="openTagPicker($event)"
            [disabled]="this.isLoading"
            [class.mat-accent]="group.value.tags?.length > 0"
          >
            <mat-icon inline class>label</mat-icon>
          </button>
        </div>
        <button
          mat-icon-button
          class="mat-button-icon-sm"
          [ngClass]="{ '!cursor-default': isLoading || group.disabled }"
          [disabled]="this.isLoading"
          (click)="toggleBilled()"
          [matTooltip]="'time.billability' | translate"
          *ngIf="!isDisabledFlag"
        >
          <mat-icon inline [class.mat-accent]="group.value.billable" class>attach_money</mat-icon>
        </button>
        <ng-container
          *ngTemplateOutlet="
            mode === 'range'
              ? rangeModeTemplate
              : mode === 'range_optional'
                ? stateVisibleMode
                  ? rangeModeTemplate
                  : durationModeTemplate
                : mode === 'duration'
                  ? durationModeTemplate
                  : null
          "
        ></ng-container>
      </div>
      <div class="relative">
        <button
          mat-icon-button
          [disabled]="isLoading || group.disabled"
          [matMenuTriggerFor]="settingsMenu"
          [matMenuTriggerData]="{ $implicit: entry }"
          (menuOpened)="dialogOpen = true"
          (menuClose)="dialogOpen = false"
        >
          <mat-icon>more_vert</mat-icon>
          <ng-container *ngTemplateOutlet="menuNoticeBadge; context: { $implicit: entry }"></ng-container>
        </button>
        <mat-menu [overlapTrigger]="true" #settingsMenu="matMenu" class="py-0">
          <div class="flex flex-col space-y-1">
            <ng-container *ngTemplateOutlet="menuNoticeTemplate; context: { $implicit: entry }"></ng-container>
            <ng-container *ngTemplateOutlet="desktopContinueTime; context: { $implicit: entry }"></ng-container>
            <button
              mat-menu-item
              [disabled]="isLoading || !entry.currentUserCanEdit"
              (click)="toggleStateVisibleMode()"
              *ngIf="mode === 'range_optional'"
              type="button"
            >
              <mat-icon>swap_horiz</mat-icon> {{ 'timer.time.changeInputMode' | translate }}
            </button>
            <mat-divider></mat-divider>
            <button
              mat-menu-item
              [disabled]="isLoading || !entry.currentUserCanEdit"
              (click)="this.openCalPicker(entry)"
              type="button"
            >
              <mat-icon>date_range</mat-icon> {{ 'date.change' | translate }}
            </button>
            <button
              mat-menu-item
              [disabled]="isLoading || group.invalid || !entry.currentUserCanEdit"
              (click)="this.updateTime(group.value)"
            >
              <mat-icon>save</mat-icon> {{ 'utils.save' | translate }}
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [disabled]="isLoading || !entry.currentUserCanEdit" (click)="deleteEntry(entry)">
              <mat-icon>delete</mat-icon> {{ 'utils.delete' | translate }}
            </button>
          </div>
        </mat-menu>
      </div>
    </div>
  </form>
</ng-template>
<mat-menu #mobileMenu="matMenu">
  <ng-template matMenuContent let-entry>
    <div class="flex flex-col space-y-1">
      <ng-container *ngTemplateOutlet="menuNoticeTemplate; context: { $implicit: entry }"></ng-container>
      <ng-container *ngTemplateOutlet="mobileContinueTime; context: { $implicit: entry }"></ng-container>
      <mat-divider></mat-divider>
      <button mat-menu-item [disabled]="!entry.currentUserCanEdit || group.disabled" (click)="editTime(entry)">
        <mat-icon>edit</mat-icon> {{ 'utils.edit' | translate }}
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item [disabled]="!entry.currentUserCanEdit || group.disabled" (click)="deleteEntry(entry)">
        <mat-icon>delete</mat-icon> {{ 'utils.delete' | translate }}
      </button>
    </div>
  </ng-template>
</mat-menu>
<ng-template #desktopContinueTime let-entry>
  <ng-container
    *ngTemplateOutlet="(this.isManualMode$ | async) ? manualMode : automaticMode; context: { $implicit: entry }"
  ></ng-container>
</ng-template>
<ng-template #mobileContinueTime let-entry>
  <ng-container *ngTemplateOutlet="automaticMode; context: { $implicit: entry }"></ng-container>
  <ng-container *ngTemplateOutlet="manualMode; context: { $implicit: entry }"></ng-container>
</ng-template>
<ng-template #manualMode let-entry>
  <button
    mat-menu-item
    [disabled]="isLoading || entry.project?.completed || group.disabled"
    (click)="this.copyEntry(entry)"
  >
    <mat-icon class="mat-accent">content_copy</mat-icon> {{ 'time.copy' | translate }}
  </button>
</ng-template>
<ng-template #automaticMode let-entry>
  <button
    mat-menu-item
    [disabled]="isLoading || entry.project?.completed || group.disabled"
    (click)="this.continueEntry(entry)"
  >
    <mat-icon class="mat-accent">play_circle_filled</mat-icon> {{ 'task.continue' | translate }}
  </button>
</ng-template>
<ng-template #skeletonPlaceholder>
  <div class="h-full">
    <div class="mat-skeleton"></div>
  </div>
</ng-template>
<ng-template #durationHoverCard let-timeDiff="timeDiff" let-start="start" let-end="end">
  <div class="flex flex-row items-center justify-start space-x-1">
    <span>{{ 'timer.time.duration' | translate }}:</span>
    <span>{{ timeDiff | parseMsAsDuration: true }}</span>
  </div>
</ng-template>
<ng-template #projectPickerTooltip let-client="client" let-project="project" let-task="task" let-tags="tags">
  <div class="my-2 flex flex-col space-y-2 px-2.5 wflex-[1_0_220px]">
    <div class="space-y-1">
      <h3 class="mat-hint text-sm italic" translate>client.single</h3>
      <div class="font-semibold">
        {{ client | defaultEntityTranslate: 'client' : 'client.none' : ('client.none' | translate) }}
      </div>
    </div>
    <div class="space-y-1">
      <h3 class="mat-hint text-sm italic" translate>project.single</h3>
      <div class="font-semibold">{{ project | defaultEntityTranslate: 'project' : 'project.none' }}</div>
    </div>
    <div class="space-y-1" *ngIf="(project | projectProxy)?.description as desc">
      <h3 class="mat-hint text-sm italic" translate>Description</h3>
      <div>{{ desc | truncate: 120 }}</div>
    </div>
    <ng-container *ngIf="tags && tags.length > 0">
      <div class="space-y-1">
        <h3 class="mat-hint text-sm italic" translate>tag.plural</h3>
        <div class="flex flex-wrap gap-2">
          <div
            class="mbg-card pointer-events-none h-6 cursor-default select-none truncate rounded-full px-2.5 text-base leading-6"
            *ngFor="let item of tags; trackBy: trackId"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #menuNoticeTemplate let-entry>
  <div class="alert alert-info cursor-default rounded !py-1.5 !text-sm" click.native *ngIf="entry.hasTimeOverlap">
    {{ 'time.overlaptime_not_allow_workspace' | translate }}
  </div>
</ng-template>
<ng-template #menuNoticeBadge let-entry>
  <div (click)="$event.stopPropagation(); $event.preventDefault()" class="flex flex-col gap-1.5">
    <div
      class="absolute bottom-full left-full z-10 -mb-2 -ml-2 h-2 w-2 select-none rounded-full bg-red-500"
      *ngIf="entry.hasTimeOverlap"
    ></div>
  </div>
</ng-template>
<ng-template #startEndTemplate>
  <form
    (submit)="$event.preventDefault()"
    [formGroup]="group.controls.time"
    #timespanRef
    class="flex flex-row items-center justify-end space-x-2.5"
  >
    <div class="w-[86px] flex-shrink-0 pb-0">
      <app-time-input
        formControlName="start"
        #startInp
        elementRef
        #startInpRef="nativeElement"
        (focus)="this.selectInput($event, startInpRef)"
        (click)="this.selectInput($event, startInpRef)"
        [displayFormat]="false"
        placeholder="00:00"
      >
        <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
          <mat-form-field
            fxFill
            [matTooltip]="inputPlaceholder | translate"
            appearance="fill"
            class="mat-form-field-no-hint mat-form-field-compact mat-form-field-plain text-center"
          >
            <input type="text" matInput [formControl]="control" />
          </mat-form-field>
        </ng-template>
      </app-time-input>
    </div>
    <mat-icon inline>arrow_forward</mat-icon>
    <div class="w-[86px] flex-shrink-0 pb-0">
      <app-time-input
        formControlName="end"
        #endInp
        elementRef
        #endInpRef="nativeElement"
        (focus)="this.selectInput($event, endInpRef)"
        (click)="this.selectInput($event, endInpRef)"
        [displayFormat]="false"
        placeholder="00:00"
      >
        <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
          <mat-form-field
            fxFill
            appearance="fill"
            [matTooltip]="inputPlaceholder | translate"
            class="mat-form-field-no-hint mat-form-field-compact mat-form-field-plain text-center"
          >
            <input type="text" matInput [formControl]="control" />
          </mat-form-field>
        </ng-template>
      </app-time-input>
    </div>
  </form>
</ng-template>
<ng-template #rangeModeTemplate>
  <ng-container *ngTemplateOutlet="startEndTemplate"></ng-container>
  <div
    class="badge badge-duration cursor-default"
    matTooltip
    [matTooltipTemplate]="durationHoverCard"
    [matTooltipTemplateContext]="entry$ | async"
  >
    {{ entry$timeDiff | async | parseMsAsDuration: false }}
  </div>
</ng-template>
<ng-template #rangeOptModeTemplate>
  <ng-container *ngTemplateOutlet="startEndTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="durationModeTemplate"></ng-container>
</ng-template>
<ng-template #durationModeTemplate>
  <form
    (submit)="$event.preventDefault()"
    [formGroup]="group.controls.time"
    #timespanRef
    class="flex flex-row items-center justify-start space-x-2.5"
  >
    <div class="w-[86px] flex-shrink-0 pb-0">
      <tg-duration-input-control
        formControlName="duration"
        #endInp
        elementRef
        #endInpRef="nativeElement"
        (focus)="this.selectInput($event, endInpRef)"
        (click)="this.selectInput($event, endInpRef)"
        displayFormat="HH:mm"
        placeholder="00:00"
        inputPlaceholder="timer.time.duration"
      >
        <ng-template durationInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
          <mat-form-field
            fxFill
            appearance="fill"
            [matTooltip]="inputPlaceholder | translate"
            class="mat-form-field-no-hint mat-form-field-compact mat-form-field-plain text-center"
          >
            <input type="text" matInput [formControl]="control" />
          </mat-form-field>
        </ng-template>
      </tg-duration-input-control>
    </div>
  </form>
</ng-template>
