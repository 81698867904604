import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'ngx-date-fns';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TimeTrackerEntryModule } from '../time-tracker-entry/time-tracker-entry.module';
import { TimeTrackerTableCardContentDirective } from './time-tracker-table-card-content.directive';
import { TimeTrackerTableCardComponent } from './time-tracker-table-card/time-tracker-table-card.component';
import { TimeTrackerTableComponent } from './time-tracker-table.component';

@NgModule({
  declarations: [TimeTrackerTableComponent, TimeTrackerTableCardComponent, TimeTrackerTableCardContentDirective],
  imports: [
    CommonModule,
    MaterialModule,
    TimeTrackerEntryModule,
    TranslateModule,
    NgxSkeletonLoaderModule,
    CustomDirectivesModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    DateFnsModule,
  ],
  exports: [TimeTrackerTableComponent],
})
export class TimeTrackerTableModule {}
