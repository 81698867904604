import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { TimeInputModule } from '@app/shared/time-input/time-input.module';
import { UserAvatarModule } from '@app/shared/user-avatar/user-avatar.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserSinglePickerDialogModule } from '../user-single-picker-dialog/user-single-picker-dialog.module';
import { ComeAndGoUpdateDialogComponent } from './come-and-go-update-dialog.component';

@NgModule({
  declarations: [ComeAndGoUpdateDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    UserAvatarModule,
    UserSinglePickerDialogModule,
    TimeInputModule,
  ],
})
export class ComeAndGoUpdateDialogModule {}
