export const SVG_ICON_REG = {
  man: '@/assets/svg/man.svg',
  logo: '@/assets/img/logo_black2.svg',
  logoIcon: '@/assets/timeghost_logo.svg',
  ftimer: '@/assets/svg/f_watch.svg',
  fdashboard: '@/assets/svg/f_grid.svg',
  fcalendar: '@/assets/svg/f_calendar.svg',
  factivity: '@/assets/svg/f_activity.svg',
  fautomation: '@/assets/svg/f_zap.svg',
  finsights: '@/assets/svg/f_insights.svg',
  fsupervisor: '@/assets/svg/f_supervisor.svg',
  office: '@/assets/svg/office.svg',
  ghost: '@/assets/svg/ghost.svg',
  'apple-add-app': '@/assets/svg/apple-store-add.svg',
  'android-add-app': '@/assets/svg/google-play-add.svg',
  record_help_1: '@/assets/svg/ghost_timeronboarding_01.svg',
  record_help_2: '@/assets/svg/ghost_timeronboarding_02.svg',
  record_help_3: '@/assets/svg/ghost_timeronboarding_03.svg',
  record_help_timer_3: '@/assets/svg/ghost_timeronboarding_timer_03.svg',
  record_help_4: '@/assets/svg/ghost_timeronboarding_04.svg',
  ms_teams: '@/assets/svg/office/teams_48x1.svg',
  ms_word: '@/assets/svg/office/word_48x1.svg',
  ms_powerpoint: '@/assets/svg/office/powerpoint_48x1.svg',
  ms_outlook: '@/assets/svg/office/outlook_48x1.svg',
  ms_todo: '@/assets/svg/office/todo_48x1.svg',
  ms_onedrive: '@/assets/svg/office/onedrive_48x1.svg',
  ms: '@/assets/svg/microsoft.svg',
  youtube: '@/assets/svg/youtube.svg',
  gift: '@/assets/svg/gift.svg',
};
