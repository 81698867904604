import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { ComeAndGoUpdateDialogModule } from '@app/components/come-and-go-update-dialog/come-and-go-update-dialog.module';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'ngx-date-fns';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TimeComegoViewComponent } from './time-comego-view.component';

@NgModule({
  declarations: [TimeComegoViewComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,

    NgxSkeletonLoaderModule,
    CustomDirectivesModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    DateFnsModule,
    ComeAndGoUpdateDialogModule,
  ],
  exports: [TimeComegoViewComponent],
})
export class TimeComegoViewModule {}
