import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppService } from '@app/app.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, fromEvent } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'tg-offline-dialog',
  templateUrl: './offline-dialog.component.html',
  styleUrls: ['./offline-dialog.component.scss'],
})
export class OfflineDialogComponent implements OnInit {
  constructor(
    private appService: AppService,
    private ref: MatDialogRef<OfflineDialogComponent>,
  ) {}
  isOnline$ = this.appService.isOnline$;
  ngOnInit(): void {
    this.ref.updateSize('280px', '200px');
    this.ref.disableClose = true;
  }
  reload() {
    location.reload();
  }
}
