import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DurationInputControlModule } from '@app/components/duration-input-control/duration-input-control.module';
import { TaskPickerDialogModule } from '@app/components/task-picker-dialog/task-picker-dialog.module';
import { UserSinglePickerDialogModule } from '@app/components/user-single-picker-dialog/user-single-picker-dialog.module';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'ngx-date-fns';
import { TimeInputModule } from '../time-input/time-input.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { TimeTrackerCalendarUpdateDialogComponent } from './time-tracker-calendar-update-dialog.component';

@NgModule({
  declarations: [TimeTrackerCalendarUpdateDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,

    TimeInputModule,
    DurationInputControlModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TaskPickerDialogModule,
    UserAvatarModule,
    UserSinglePickerDialogModule,
    DateFnsModule,
  ],
})
export class TimeTrackerCalendarUpdateDialogModule {}
