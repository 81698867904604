import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { coerceObjectProperty } from '@app/_helpers/object';
import { fetchMessageChannel, TGMessageBase } from '@app/_helpers/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, fromEvent, map } from 'rxjs';
import { Logger, UserSettingsQuery } from 'timeghost-api';
const log = new Logger('[WS Change Message]');
export interface AlertWorkspaceMessage extends TGMessageBase {
  workspaceId: string;
}
export function createWorkspaceChangeObserver() {
  const channel = fetchMessageChannel();
  return fromEvent(channel, 'message', { passive: true }).pipe(
    map((x: MessageEvent) => {
      if (x.target !== channel) return null;
      const obj = x.source !== window && coerceObjectProperty<AlertWorkspaceMessage>(x?.data);
      if (!obj) return null;
      if (obj._source === 'tg') return obj;
      return null;
    }),
    filter(Boolean),
  );
}
@UntilDestroy()
@Component({
  selector: 'tg-alert-workspace-change-dialog',
  templateUrl: './alert-workspace-change-dialog.component.html',
  styleUrls: ['./alert-workspace-change-dialog.component.scss'],
})
export class AlertWorkspaceChangeDialogComponent implements OnInit {
  constructor(
    private userSettingsQuery: UserSettingsQuery,
    private ref: MatDialogRef<any>,
  ) {}
  ngOnInit(): void {
    createWorkspaceChangeObserver()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        const user = this.userSettingsQuery.getValue();
        const wsId = user?.workspace?.id;
        if (wsId === x.workspaceId) this.ref.close();
        log.debug('ws changed...');
      });
  }
  reload() {
    location.reload();
  }
}
