import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ClientprojectPickerModule } from '../clientproject-picker/clientproject-picker.module';
import { RecordToolbarComponent } from './record-toolbar.component';
// import { StaticDTPickerModule } from '../static-dtpicker/static-dtpicker.module';
import { A11yModule } from '@angular/cdk/a11y';
import { EntityRefPipesModule } from '@app/_pipes/entity-ref-pipes/entity-ref-pipes.module';
import { TimeRangeControlModule } from '@app/components/time-range-control/time-range-control.module';
import { FabricBaseIconModule } from '@app/modules/fabric/fabric-base-icon/fabric-base-icon.module';
import { FabricFileIconModule } from '@app/modules/fabric/fabric-file-icon/fabric-file-icon.module';
import { ParseFeedDescriptionModule } from '@app/pages/feed-page/pipes/parse-feed-description.module';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { DateFnsModule } from 'ngx-date-fns';
import { ClientProjectPickerDialogModule } from '../dialogs/client-project-picker-dialog/client-project-picker-dialog.module';
import { ClientProjectPickerStepperDialogModule } from '../dialogs/client-project-picker-stepper-dialog/client-project-picker-stepper-dialog.module';
import { TagPickerDialogModule } from '../dialogs/tag-picker-dialog/tag-picker-dialog.module';
import { DatepickerMenuModule } from '../menus/datepicker-menu/datepicker-menu.module';
import { StaticDatePickerModule } from '../static-datepicker/static-dtpicker.module';
import { TimeInputModule } from '../time-input/time-input.module';
import { RecordToolbarCounterComponent } from './record-toolbar-counter/record-toolbar-counter.component';
import { RecordToolbarService } from './record-toolbar.service';

@NgModule({
  declarations: [RecordToolbarComponent, RecordToolbarCounterComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MaterialModule,
    TranslateModule,
    ClientprojectPickerModule,
    StaticDatePickerModule,
    SatPopoverModule,
    DatepickerMenuModule,
    ClientProjectPickerDialogModule,
    ClientProjectPickerStepperDialogModule,
    TagPickerDialogModule,
    DateFnsModule,
    EntityRefPipesModule,
    TimeInputModule,
    ParseFeedDescriptionModule,
    FabricFileIconModule,
    FabricBaseIconModule,
    TimeRangeControlModule,
    A11yModule,
  ],
  exports: [RecordToolbarComponent],
})
export class RecordToolbarModule {
  /**
   * allows saving state without refetching on page load
   */
  static forRoot(): ModuleWithProviders<RecordToolbarModule> {
    return {
      ngModule: RecordToolbarModule,
      providers: [RecordToolbarService],
    };
  }
}
