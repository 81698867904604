<div *ngIf="comegoEnabled$ | async" class="flex flex-col items-center justify-end gap-3">
  <div class="flex items-end justify-end space-x-2">
    @if (switchEnabled() === true) {
      <div class="mb-2 flex items-center space-x-2">
        <button
          mat-raised-button
          mat-icon-button
          color="light"
          (click)="onSwitch.emit('project')"
          [disabled]="loading$.value$ | async"
          [matTooltip]="'Swap to project times'"
          matTooltipPosition="left"
        >
          <mat-icon>swap_horizontal</mat-icon>
        </button>
      </div>
    }
    <div class="flex flex-col items-center justify-end gap-3">
      @if (canAdd$.value$ | async) {
        <button
          mat-raised-button
          mat-icon-button
          (click)="openCreateDialog()"
          [disabled]="loading$.value$ | async"
          [matTooltip]="'comego.manual-create' | translate"
        >
          <mat-icon>add</mat-icon>
        </button>
      }
      <ng-container *ngIf="this.comegoRunning$.value$ | async as tr">
        <button
          mat-raised-button
          mat-icon-button
          class="bg-gray-50 text-red-500"
          (click)="delete(tr.id, confirmDeleteDialog)"
          [disabled]="loading$.value$ | async"
          [matTooltip]="'comego.delete' | translate"
          matTooltipPosition="left"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          mat-raised-button
          mat-icon-button
          color="light"
          (click)="pause(tr.id)"
          [disabled]="loading$.value$ | async"
          [matTooltip]="'comego.state.pause' | translate"
          matTooltipPosition="left"
          *ngIf="tr.type === 'work'"
        >
          <mat-icon>pause</mat-icon>
        </button>
        <button
          mat-raised-button
          mat-icon-button
          color="light"
          (click)="resume(tr.id)"
          [disabled]="loading$.value$ | async"
          [matTooltip]="'comego.state.resume' | translate"
          matTooltipPosition="left"
          *ngIf="tr.type === 'pause'"
        >
          <mat-icon>not_started</mat-icon>
        </button>
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          (loading$.value$ | async) ? loadingFab : normalFabTimer;
          context: { time: (running$ | async) }
        "
      ></ng-container>
    </div>
  </div>
  <ng-template #normalFabTimer let-time="time" let-status>
    <button
      mat-fab
      (click)="time ? stopRecord() : startRecord()"
      [disabled]="!time && !(canAdd$.value$ | async)"
      matTooltip
      [matTooltipTemplate]="tooltipCounter"
      [matTooltipTemplateContext]="{ time: time }"
      matTooltipPosition="left"
      fxHide.lt-lg
      [ngClass]="time ? 'mat-comego-stop' : 'mat-comego-start'"
    >
      <mat-icon *ngIf="!time; else runningTime">login</mat-icon>
      <ng-template #runningTime>
        <mat-icon>logout</mat-icon>
      </ng-template>
    </button>
    <div class="relative" fxHide.gt-md>
      <button
        mat-fab
        (click)="time ? stopRecord() : startRecord()"
        [ngClass]="time ? 'mat-comego-stop' : 'mat-comego-start'"
      >
        <mat-icon *ngIf="!time; else runningTime">login</mat-icon>
        <ng-template #runningTime>
          <mat-icon>logout</mat-icon>
        </ng-template>
      </button>
      <div
        class="mbg-tooltip absolute bottom-0 right-full mr-2 block min-w-48 rounded px-2.5 py-2 text-left shadow"
        *ngIf="time && !time.end"
      >
        <ng-container *ngTemplateOutlet="tooltipCounter; context: { time: time }"></ng-container>
      </div>
    </div>
  </ng-template>
  <ng-template #loadingFab let-time="time">
    <button mat-fab disabled>
      <mat-progress-spinner [strokeWidth]="3" [diameter]="20" color="accent" [mode]="'indeterminate'">
      </mat-progress-spinner>
    </button>
  </ng-template>
</div>
<ng-template #tooltipCounter let-time="time">
  <ng-container *ngIf="time && !time.end; else tooltipRecordAction">
    <div class="flex flex-row items-center justify-start gap-2">
      <mat-icon>timer</mat-icon>
      <div class="flex flex-col">
        <span class="font-bold">{{ 'comego.widget.checkout' | translate }}</span>
        <span class="mt-1">{{
          time.start | dfnsParseIso | dfnsDifferenceInSeconds: time.now | parseMsAsDuration: true
        }}</span>
      </div>
    </div>
  </ng-container>
  <ng-template #tooltipRecordAction>
    {{ 'comego.widget.start' | translate }}
  </ng-template>
</ng-template>

<ng-template #confirmDeleteDialog let-ref="dialogRef" let-data>
  <div matDialogTitle class="mat-dialog-title-base flex flex-col">
    <h4 class="my-0">{{ 'utils.confirmDeletion' | translate }}</h4>
  </div>
  <mat-dialog-content>
    <p>{{ 'comego.confirmDelete' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button (click)="ref.close(false)">
      {{ 'utils.cancel' | translate }}
    </button>
    <button mat-stroked-button color="warn" (click)="ref.close(true)">
      {{ 'utils.delete' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
