import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewContainerRef } from '@angular/core';
import { DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { WeekRangeSelectionStrategyService } from '@app/services/mat-calendar/week-range-selection-strategy.service';
import { SatPopoverComponent as SatPopover, SatPopoverAnchoringService } from '@ncstate/sat-popover';
import { endOfWeek, startOfWeek } from 'date-fns/esm';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'tg-time-tracker-calendar-week-picker',
  templateUrl: './time-tracker-calendar-week-picker.component.html',
  styleUrls: ['./time-tracker-calendar-week-picker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: WeekRangeSelectionStrategyService,
    },
  ],
})
export class TimeTrackerCalendarWeekPickerComponent extends SatPopover implements OnInit {
  constructor(
    _focusTrapFactory: ConfigurableFocusTrapFactory,
    _anchoringService: SatPopoverAnchoringService,
    _viewContainerRef: ViewContainerRef,
    @Optional() @Inject(DOCUMENT) _document: any,
    @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
    private pickerStrategy: WeekRangeSelectionStrategyService<Date>,
  ) {
    super(_focusTrapFactory, _anchoringService, _viewContainerRef, '200ms cubic-bezier(0.25, 0.8, 0.25, 1)', _document);
    this.hasBackdrop = true;
  }
  ngOnInit(): void {}
  private _viewDate = new BehaviorSubject<Date>(null);
  readonly viewDate$ = this._viewDate.asObservable().pipe(distinctUntilChanged());
  readonly viewDate$range = this.viewDate$.pipe(
    map((x) => {
      return x ? new DateRange(startOfWeek(x, { weekStartsOn: 1 }), endOfWeek(x, { weekStartsOn: 1 })) : null;
    }),
  );
  get viewDate() {
    return this._viewDate.getValue();
  }
  @Input()
  set viewDate(val: Date) {
    this._viewDate.next(val);
    this.viewDateChange.emit(val);
  }
  @Output()
  viewDateChange = new EventEmitter<Date>(true);

  @Output()
  rangeChange = new EventEmitter<DateRange<Date>>(true);
  selectedChange(ev: Date) {
    const newRange = this.pickerStrategy.selectionFinished(ev);
    this.viewDate = newRange.start;
    this.rangeChange.emit(newRange);
  }
}
