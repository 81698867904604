<sat-popover #appMenu [hasBackdrop]="true" [interactiveClose]="true" class="menuWidth">
  <mat-card class="mat-elevation-z4 !p-0">
    <mat-card-content class="flex flex-col">
      <div class="searchWrapper flex flex-col justify-stretch">
        <div class="searchInput flex flex-col px-3">
          <mat-form-field class="w-full no-underline" [floatLabel]="'never'">
            <input
              type="search"
              [placeholder]="('utils.search' | translate) + '...'"
              [formControl]="this.searchinput"
              matInput
            />
          </mat-form-field>
        </div>
        <div class="searchContainer flex flex-col">
          <mat-list dense>
            <ng-container
              *ngFor="
                let entry of this.data$filtered | async;
                let last = last;
                let first = first;
                trackBy: trackFiltered
              "
            >
              <h3 matSubheader [innerText]="entry.client.name" class="--client-title text-ellipsis"></h3>

              <mat-list-item
                matRipple
                *ngFor="let project of entry.projects"
                (click)="this.selectItem(project)"
                class="cursor-pointer"
                [class.bg-primary-2]="this.SelectedProject?.id === project.id"
              >
                <ng-container *ngIf="this.SelectedProject?.id === project.id; else nativeName">
                  <mat-checkbox
                    [labelPosition]="'after'"
                    class="mat-checkbox-flex mat-checkbox-radio w-full"
                    (click)="$event.preventDefault()"
                    color="primary"
                    [checked]="true"
                  >
                    <span class="text-ellipsis">{{
                      project | defaultEntityTranslate: 'project' : 'project.none'
                    }}</span>
                  </mat-checkbox>
                </ng-container>
                <ng-template #nativeName>
                  {{ project | defaultEntityTranslate: 'project' : 'project.none' }}
                </ng-template>
              </mat-list-item>
              <mat-divider *ngIf="!first && !last"></mat-divider>
            </ng-container>
          </mat-list>
        </div>
      </div>
      <div class="flex-auto"></div>
      <div class="flex flex-col items-stretch justify-end">
        <div class="rounded-bottom p-3">
          <button class="w-full" mat-stroked-button (click)="this.createProjectDialog()">
            {{ 'project.create' | translate }}
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</sat-popover>
