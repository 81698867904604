import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { StaticDatePickerComponent } from './static-dtpicker.component';

@NgModule({
  declarations: [StaticDatePickerComponent],
  imports: [
    CommonModule,
    MaterialModule,

    ReactiveFormsModule,
    FormsModule,
    CustomDirectivesModule,
    MatDatepickerModule,
    MatButtonModule,
    TranslateModule,
  ],
  exports: [StaticDatePickerComponent],
})
export class StaticDatePickerModule {}
