interface NotifyOptions {
  enabled?: boolean;
  hide?: {
    easing?: string;
    offset?: number | false;
    preset?: string;
    speed?: number;
  };
  overlap?: number | false;
  shift?: {
    easing?: string;
    speed?: number;
  };
  show?: {
    easing?: string;
    preset?: string;
    speed?: number;
  };
}
export const NotifierAnimations: NotifyOptions = {
  enabled: true,
  show: {
    preset: 'slide',
    speed: 150,
    easing: 'ease',
  },
  hide: {
    preset: 'fade',
    speed: 100,
    easing: 'ease',
    offset: 20,
  },
  shift: {
    speed: 150,
    easing: 'ease',
  },
  overlap: 150,
};
