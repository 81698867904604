import { Directive } from '@angular/core';
import { ShellComponent } from './shell.component';

@Directive({
  selector: '[routerLink]',
  host: {
    '(click)': 'onClick($event)',
  },
})
export class ShellRouterLinkDirective {
  constructor(private shell: ShellComponent) {}
  onClick(ev: Event) {
    if (this.shell.isMobile) this.shell.sidenav.close();
  }
}
