import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { UserAvatarModule } from '@app/shared/user-avatar/user-avatar.module';
import { TranslateModule } from '@ngx-translate/core';
import { CreateWorkspaceModule } from '../create-workspace/create-workspace.module';
import { WorkspaceChangeConfirmDialogModule } from '../workspace-change-confirm-dialog/workspace-change-confirm-dialog.module';
import { WorkspaceDialogComponent } from './workspace-dialog.component';

@NgModule({
  declarations: [WorkspaceDialogComponent],
  imports: [
    CommonModule,

    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CreateWorkspaceModule,
    UserAvatarModule,
    WorkspaceChangeConfirmDialogModule,
  ],
  exports: [WorkspaceDialogComponent],
})
export class WorkspaceDialogModule {}
