import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { CustomValidators } from '@app/_validators/custom-validators';
import { environment } from '@env/environment';
import { isTeamsWindow } from '@env/msal';
import { ListIteratee, Many, ValueIteratee } from 'lodash';
import { clamp, find as findBy, sortBy, uniq, uniqBy } from 'lodash-es';
import { Observable } from 'rxjs';
export interface ParseMSOptions {
  showHours: boolean;
  showMinutes: boolean;
  showSeconds: boolean;
}

export default () => {
  Array.prototype.insert = function <T>(index: number, item: T) {
    this.splice(index, 0, item);
  };
  Array.prototype.diff = function <T>(b: T[]) {
    return [...this.filter((x: T) => !b.includes(x)), ...b.filter((x: T) => !this.includes(x))];
  };
  Array.prototype.uniqBy = function <T>(entity: ValueIteratee<T>) {
    return uniqBy<T>(this, entity);
  };
  Array.prototype.uniq = function () {
    return uniq(this);
  };
  Array.prototype.isUniqBy = function <T = any>(findFn: ValueIteratee<T>) {
    return !!(this as Array<T>).reduce((isUnique, next) => {
      if (isUnique) {
        if (findBy(this, findFn)) isUnique = false;
        return isUnique;
      }
      return isUnique;
    }, true);
  };
  Array.prototype.lsort = function <T1, T2 extends Many<ListIteratee<T1>> = Many<ListIteratee<T1>>, R = Array<T1>>(
    ...iteratees: T2[]
  ): R {
    return sortBy(this as T1[], ...iteratees) as R;
  };
  Array.prototype.sum<number> = function (min: number = 0) {
    return (this as number[]).reduce((wcc, w) => (wcc += w), min);
  };
  Boolean.prototype.asNumber = function () {
    return this ? 1 : 0;
  };
  String.prototype.insert = function <T>(index: number, item: T) {
    return (this as string).substring(0, index) + item + (this as string).substring(index);
  };
  Number.prototype.clamp = function (min: number, max: number) {
    return clamp(this, min, max);
  };
  Number.prototype.clampThis = function (min: number) {
    return clamp(this, min, this);
  };
  Number.prototype.toTimeFixed = function (decimals: number, parseOptions?: Partial<ParseMSOptions>) {
    const x = this,
      mDiff = Date.now() + x - Date.now();
    let m = new Date(mDiff < 0 ? mDiff * -1 : mDiff);
    let mHours = m.getTime() / (1000 * 60 * 60),
      mMinutes = m.getMinutes(),
      mSeconds = m.getSeconds();
    let options: ParseMSOptions = {
      showHours: true,
      showMinutes: true,
      showSeconds: true,
    };
    options = Object.assign(options, parseOptions);
    let hours = Math.floor(mHours).toFixed(decimals),
      minutes = Math.floor(mMinutes).toFixed(decimals),
      seconds = Math.floor(mSeconds).toFixed(decimals);
    if (mHours < 10) {
      hours = `0${hours}`;
    }
    if (mMinutes < 10) {
      minutes = `0${minutes}`;
    }
    if (mSeconds < 10) {
      seconds = `0${seconds}`;
    }
    let ret: string = '';
    if (options.showHours) {
      ret += `${hours}:`;
    }
    if (options.showMinutes) {
      ret += `${minutes}:`;
    }
    if (options.showSeconds) {
      ret += `${seconds}:`;
    }
    if (ret.endsWith(':')) {
      ret = ret.slice(0, ret.length - 1);
    }
    return ret;
  };
  Window.prototype.parseEnum = function <ET, T>(enumObj: ET, str: keyof ET): T {
    return enumObj[<string>str];
  };
  Date.prototype.toUTCDate = function () {
    return new Date(this.getTime() - this.getTimezoneOffset() * 60000);
  };
  UntypedFormGroup.prototype.useMustChange = function (value?: any, onlyDisabled?: boolean) {
    const validators: ValidatorFn[] = [];
    if ((this as AbstractControl).validator) validators.push((this as AbstractControl).validator);
    validators.push(CustomValidators.mustChange(value ?? this.value, onlyDisabled));
    this.setValidators(validators);
    return this;
  };
  UntypedFormGroup.prototype.setMustChange = function (value?: any, onlyDisabled?: boolean, mapper?: any) {
    const validators: ValidatorFn[] = [];
    validators.push(CustomValidators.mustChange(value ?? this.value, onlyDisabled, mapper));
    this.setValidators(validators);
    return this;
  };
  Observable.prototype.toPromise = function <T>(): Promise<T> {
    return new Promise((resolve, reject) => {
      let value: T | undefined;
      this.subscribe(
        (x: T) => (value = x),
        (err: any) => reject(err),
        () => resolve(value),
      );
    }) as Promise<T | undefined>;
  };
  if (!environment.production) {
    const url = new URL(location.href);
    const teamsTest = url.searchParams.get('ui_teams_test');
    window.teams_test = teamsTest;
    console.log('ui teams test', { teamsTest });
  }
  if (isTeamsWindow()) {
    document.documentElement.classList.add('timeghost-teams');
  }
};
