import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { AlertWorkspaceChangeDialogComponent } from './alert-workspace-change-dialog.component';

@NgModule({
  declarations: [AlertWorkspaceChangeDialogComponent],
  imports: [CommonModule, MaterialModule, TranslateModule],
})
export class AlertWorkspaceChangeDialogModule {}
