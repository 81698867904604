import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { AppService } from '@app/app.service';
import { createRxValue, createScheduleDayParserFromUser } from '@app/_helpers/utils';
import { endOfDay } from 'date-fns/esm';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Time, UserSettingsQuery } from 'timeghost-api';

import { TimeTrackerTableCardContentDirective } from '../time-tracker-table-card-content.directive';
import { EntryItem } from '../time-tracker-table.component';

@Component({
  selector: 'app-time-tracker-table-card',
  templateUrl: './time-tracker-table-card.component.html',
  styleUrls: ['./time-tracker-table-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'tg-time-card-section',
  },
})
export class TimeTrackerTableCardComponent implements OnInit {
  @ContentChild(TimeTrackerTableCardContentDirective, { read: TemplateRef, static: true }) template: TemplateRef<any>;
  constructor(private userSettingsQuery: UserSettingsQuery, private appService: AppService) {}
  ngOnInit(): void {}
  private _data = createRxValue<EntryItem>(null);

  @Input()
  public get data(): EntryItem {
    return this._data.value;
  }
  public set data(v: EntryItem) {
    this._data.next(v);
  }
  readonly data$ = this._data.asObservable();
  readonly comegoEnabled$ = this.appService.comegoEnabled$;
  readonly schedule$ = combineLatest([this.data$, this.userSettingsQuery.select()]).pipe(
    map(([entry, user]) => {
      if (!entry || !entry.times?.length) return null;
      const firstTime = entry.date,
        now = Date.now();
      const currentDay = firstTime.getUTCDay();
      const parser = createScheduleDayParserFromUser(user, firstTime);
      if (!parser) return null;
      const stats = parser(
        entry.times.reduce((acc, r) => {
          if (r.times.length) acc.push(...r.times);
          return acc;
        }, [] as Time[]),
        firstTime
      );
      if (!stats?.enabled) return stats;
      const dateOfReference = endOfDay(firstTime.getTime()),
        isPastSchedule = now > dateOfReference.getTime(),
        pastFailure = isPastSchedule && stats.usage < stats.cap;
      return {
        ...stats,
        percent: stats.usage / stats.cap,
        isPastSchedule,
        pastFailure,
        graphState: pastFailure ? 'warn' : stats.usage < stats.cap ? 'accent' : 'success',
      };
    })
  );
  readonly data$stats = this._data.asObservable().pipe(
    map((entry) => {
      const times = entry.times.filter((x) => x.min && x.max).reduce((l, r) => l.concat(r.times), <Time[]>[]);
      const timeSum = times.filter((x) => x.timeDiff > 0).reduce((l, r) => (l += ~~r.timeDiff), 0);
      const billable = times.filter((x) => x.timeDiff > 0 && x.billable).reduce((l, r) => (l += ~~r.timeDiff), 0);
      const nonBillable = times.filter((x) => x.timeDiff > 0 && !x.billable).reduce((l, r) => (l += ~~r.timeDiff), 0);
      const billablePercentage = (billable / timeSum) * 100;
      return {
        billable,
        billablePercentage,
        billablePercentageFormat: billablePercentage.toFixed(0),
        nonBillable,
        nonBillablePercentage: (nonBillable / timeSum) * 100,
        timeSum,
      };
    })
  );
}
