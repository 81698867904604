import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { environment } from '@env/environment.prod';
import {
  SatPopoverComponent as SatPopover,
  SatPopoverAnchoringService,
  SatPopoverOpenOptions,
  SatPopoverVerticalAlign,
} from '@ncstate/sat-popover';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Logger } from 'timeghost-api';

const log = new Logger('StaticDatePickerComponent');
@Component({
  selector: 'app-static-date-picker',
  templateUrl: './static-dtpicker.component.html',
  styleUrls: ['./static-dtpicker.component.scss'],
})
export class StaticDatePickerComponent implements OnInit, OnDestroy, AfterViewInit {
  _onDestroy = new Subject<void>();
  private _id: string;
  get id() {
    if (!this._id) {
      this._id = Math.random().toString(36).substring(7);
    }
    return this._id;
  }
  @ViewChild(SatPopover, { static: false }) private appMenu: SatPopover;
  @ViewChild('appDate', { static: true }) private appDate: MatCalendar<Date>;
  open(options?: SatPopoverOpenOptions) {
    if (!this.closed) {
      return;
    }
    this.anchorService.anchor(this.appMenu, this.viewContainer, this.satAnchor);
    log.debug('open stpicker', [this.satAnchor, this.appMenu._templateRef]);
    this.appMenu.open(options);
  }
  close() {
    if (!this.opened) {
      return;
    }
    this.appMenu.close();
  }
  toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }
  @Input()
  hasBackdrop = true;

  @Output()
  OnClose = new EventEmitter();

  //#region selectedDate
  @Output()
  selectedDateChange: EventEmitter<Date> = new EventEmitter();
  private _selectedDate = new BehaviorSubject<Date>(new Date());
  readonly selectedDate$ = this._selectedDate.asObservable().pipe(distinctUntilChanged());

  get selectedDate() {
    return this._selectedDate.getValue();
  }
  @Input()
  set selectedDate(val: Date) {
    if (!val) return;
    const hasChange = val.toISOString() !== this.selectedDate.toISOString();
    this._selectedDate.next(val);
    if (hasChange) this.selectedDateChange.emit(val);
  }
  //#endregion
  closed: EventEmitter<any>;
  opened: EventEmitter<any>;
  constructor(
    private viewContainer: ViewContainerRef,
    private anchorService: SatPopoverAnchoringService,
    private cdr: ChangeDetectorRef,
  ) {
    this.opened = new EventEmitter();
    this.closed = new EventEmitter();
  }
  _maxDate: Date = null;
  get maxDate() {
    return this._maxDate;
  }
  @Input()
  set maxDate(val: Date) {
    this._maxDate = val;
  }
  private _satAnchor = new BehaviorSubject<any>(null);
  readonly satAnchor$ = this._satAnchor.asObservable().pipe(distinctUntilChanged());
  get satAnchor() {
    return this._satAnchor.getValue();
  }
  @Input()
  set satAnchor(val: any) {
    val = this.getAnchor(val);
    this._satAnchor.next(val);
  }

  @Input()
  satLocation: SatPopoverVerticalAlign = 'center';
  getAnchor(anchor?: any) {
    if (!anchor) {
      return null;
    }
    if (anchor instanceof HTMLElement) {
      anchor = new ElementRef(anchor);
    }
    if (anchor instanceof ElementRef) {
      return anchor;
    }
    if (anchor instanceof TemplateRef) {
      return anchor.elementRef;
    }
    if (!!anchor._elementRef) {
      return anchor._elementRef;
    }
    return null;
  }
  get currentDate() {
    return new Date();
  }
  ngAfterViewInit() {
    this.closed = this.appMenu.closed;
    this.opened = this.appMenu.opened;
    this.closed.pipe(takeUntil(this._onDestroy)).subscribe((x) => this.OnClose.emit(x));

    if (environment.isdev) {
      this.closed.pipe(takeUntil(this._onDestroy)).subscribe((x) => log.debug);
    }
    this.anchorService.anchor(this.appMenu, this.viewContainer, this.satAnchor);
  }
  ngOnInit() {
    log.debug('init');
  }
  ngOnDestroy() {
    log.debug('destroyed');
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  userSelection(dt: Date) {
    this.selectedDate = dt;
    this.appMenu.close(dt);
  }

  @Input()
  closeAfterSelection = false;

  changeSelectedDate(ev: Date) {
    log.debug(ev);
    if (this.selectedDate?.toISOString() == ev.toISOString()) {
      return;
    }
    this.selectedDate = ev;
    if (!!this.closeAfterSelection) {
      this.close();
    }
  }
}
