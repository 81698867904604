<mat-dialog-content>
  <div class="flex h-full flex-col items-center justify-center">
    <div class="mb-4 flex select-none flex-row items-center justify-center text-4xl">
      <mat-icon inline>wifi_off</mat-icon>
    </div>
    <span translate>utils.offline</span>
    <button mat-button class="!mt-3" color="primary" (click)="reload()" [disabled]="!(isOnline$ | async)">
      {{ 'utils.reload' | translate }}
    </button>
  </div>
</mat-dialog-content>
