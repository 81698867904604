import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { HomeComponent } from './home.component';
import { Shell } from '@app/shell/shell.service';
import { AppSubscriptionGuard } from '@app/app-subscription.guard';

const routes: Routes = [
  Shell.childRoutes(
    [
      {
        path: '',
        redirectTo: '/timer',
        pathMatch: 'full',
      },
      {
        path: 'timer',
        component: HomeComponent,
        data: { title: extract('timer.single') },
      },
    ],
    {
      canActivate: [AppSubscriptionGuard],
      runGuardsAndResolvers: 'always',
    }
  ),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class HomeRoutingModule {}
