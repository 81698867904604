import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'ngx-date-fns';
import { ComeAndGoCreateDialogModule } from '../come-and-go-create-dialog/come-and-go-create-dialog.module';
import { ComeAndGoBoxComponent } from './come-and-go-box.component';

@NgModule({
  declarations: [ComeAndGoBoxComponent],
  imports: [CommonModule, MaterialModule, DateFnsModule, TranslateModule, ComeAndGoCreateDialogModule],
  exports: [ComeAndGoBoxComponent],
})
export class ComeAndGoBoxModule {}
