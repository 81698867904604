import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { pushError } from '@app/_helpers/globalErrorHandler';
import userProjectFind from '@app/_helpers/userProjectFind';
import { AppService } from '@app/app.service';
import { ProjectPageService } from '@app/pages/settings/projects/project-page/project-page.service';
import { TIME_RANGE_DEFAULT_RANGES } from '@app/shared/time-range-picker/time-range-constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import {
  Logger,
  Project,
  ProjectsQuery,
  ProjectsService,
  TasksService,
  TimesService,
  UserSettingsQuery,
  WorkspacesQuery,
} from 'timeghost-api';
const log = new Logger('ProjectPageGuard');

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ProjectPageGuard {
  static readonly LOADING_ID = 'project-page';
  constructor(
    private appService: AppService,
    private workspaceQuery: WorkspacesQuery,
    private projectsQuery: ProjectsQuery,
    private projectsService: ProjectsService,
    private projectPageService: ProjectPageService,
    private timeService: TimesService,
    private taskService: TasksService,
    private userSettingsQuery: UserSettingsQuery,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.appService.removeLoading(ProjectPageGuard.LOADING_ID);
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
      if (!next.params?.id) {
        return resolve(this.router.createUrlTree(['/not-found']));
      }
      this.appService.addLoading(ProjectPageGuard.LOADING_ID);
      return new Promise((_resolve, _reject) => {
        return this.projectsService
          .getById(next.params.id)
          .then((p) => this.projectsQuery.getEntity(p.id))
          .then(_resolve)
          .catch(_reject);
      })
        .then((x: Project) =>
          Promise.all([
            x,
            firstValueFrom(this.taskService.getByProject(x, true).pipe(untilDestroyed(this.projectPageService))),
          ]),
        )
        .then(
          ([project, tasks]) => {
            if (project) {
              if (
                project.private &&
                !userProjectFind(this.userSettingsQuery.getValue(), project, {
                  workspaceAdmin: true,
                })
              ) {
                this.appService.notifier.show({
                  type: 'error',
                  message: this.translate.instant('project.private.nopermission'),
                });
                return resolve(this.router.createUrlTree(['/not-found']));
              }
              this.projectPageService.projectTasks = tasks;
              // this.projectPageService.projectTimes = times;
              this.projectPageService.range.next(TIME_RANGE_DEFAULT_RANGES.week);
              this.projectPageService.setProject(project);
              return resolve(true);
            } else {
              return resolve(this.router.createUrlTree(['/not-found']));
            }
          },
          (err) => reject(err),
        );
    })
      .then((r: boolean | UrlTree) => {
        this.appService.removeLoading(ProjectPageGuard.LOADING_ID);
        if (r) {
          this.projectPageService.isViewContext.update(true);
        }
        return r;
      })
      .catch((err) => {
        this.appService.removeLoading(ProjectPageGuard.LOADING_ID);
        log.error(err);
        pushError(err);
        return this.router.createUrlTree(['/not-found']);
      });
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UrlTree | boolean> {
    return Promise.resolve(this.canActivate(next, state));
  }
}
