import { Component, OnInit } from '@angular/core';
import { DatepickerMenuService } from './datepicker-menu.service';

@Component({
  selector: 'app-datepicker-menu',
  templateUrl: './datepicker-menu.component.html',
  styleUrls: ['./datepicker-menu.component.scss']
})
export class DatepickerMenuComponent implements OnInit {
  constructor(private dpmService: DatepickerMenuService) {}

  ngOnInit() {}
}
