import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MaterialModule } from '@app/material.module';
import { LoaderModule } from '@app/shared/loader/loader.module';
import { TranslateModule } from '@ngx-translate/core';
import { CreateWorkspaceComponent } from './create-workspace.component';

@NgModule({
  declarations: [CreateWorkspaceComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, LoaderModule, TranslateModule],
  exports: [CreateWorkspaceComponent],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],
})
export class CreateWorkspaceModule {}
