import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DateFnsModule } from 'ngx-date-fns';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { TimeTrackerCalendarCreateDialogModule } from '../time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.module';
import { TimeTrackerCalendarStepperCreateDialogModule } from '../time-tracker-calendar-stepper-create-dialog/time-tracker-calendar-stepper-create-dialog.module';
import { TimeTrackerCalendarUpdateDialogModule } from '../time-tracker-calendar-update-dialog/time-tracker-calendar-update-dialog.module';
import { TimeTrackerCalendarWeekPickerComponent } from './time-tracker-calendar-week-picker/time-tracker-calendar-week-picker.component';
import { TimeTrackerCalendarComponent } from './time-tracker-calendar.component';

@NgModule({
  declarations: [TimeTrackerCalendarComponent, TimeTrackerCalendarWeekPickerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,

    TranslateModule,
    TimeTrackerCalendarCreateDialogModule,
    TimeTrackerCalendarUpdateDialogModule,
    DateFnsModule,
    NgxTippyModule,
    CustomDirectivesModule,
    TimeTrackerCalendarStepperCreateDialogModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports: [TimeTrackerCalendarComponent],
})
export class TimeTrackerCalendarModule {}
