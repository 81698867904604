import { Directive, ElementRef, Input, Output, HostListener, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DatepickerMenuService, DatepickerMenuConfig } from './datepicker-menu.service';
import { Logger } from 'timeghost-api';

const log = new Logger('DatepickerMenuDirective');

@Directive({
  exportAs: 'datepickerMenu',
  selector: 'datepicker-menu, [datepickerMenu]'
})
export class DatepickerMenuDirective {
  private opened = new BehaviorSubject(false);
  @Output('isOpened')
  readonly opened$ = this.opened.asObservable().pipe(distinctUntilChanged());
  @Input()
  set isOpened(val: boolean) {
    this.opened.next(val);
    log.debug('opening', val);
  }
  constructor(private _elementRef: ElementRef, private dpmService: DatepickerMenuService) {
    log.debug(this._elementRef);
  }

  @HostListener('click')
  onClick() {
    this.open();
  }
  open(config: DatepickerMenuConfig = {}) {
    log.debug('opening func', config);
    this.isOpened = true;
    return this.dpmService.open(this._elementRef, config);
  }
}
