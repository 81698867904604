import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CreateProjectModule } from '../dialogs/create-project/create-project.module';
import { ClientprojectPickerComponent } from './clientproject-picker.component';

@NgModule({
  declarations: [ClientprojectPickerComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, CreateProjectModule, TranslateModule],
  exports: [ClientprojectPickerComponent],
  providers: [],
})
export class ClientprojectPickerModule {}
