import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { TimeDatePickerComponent } from './time-date-picker.component';

@NgModule({
  declarations: [TimeDatePickerComponent],
  imports: [CommonModule, MaterialModule, TranslateModule, ReactiveFormsModule],
  exports: [TimeDatePickerComponent],
})
export class TimeDatePickerModule {}
