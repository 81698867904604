import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateRange, MatDateRangeSelectionStrategy } from '@angular/material/datepicker';
import { startOfWeek, endOfWeek } from 'date-fns/esm';

@Injectable()
export class WeekRangeSelectionStrategyService<D> implements MatDateRangeSelectionStrategy<D> {
  constructor(private _dateAdapter: DateAdapter<D>) {}

  selectionFinished(date: D | null): DateRange<D> {
    return this._createFiveDayRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this._createFiveDayRange(activeDate);
  }

  private _createFiveDayRange(_date: D | null): DateRange<D> {
    if (_date) {
      const date = new Date(_date as any);
      const start = startOfWeek(new Date(date), { weekStartsOn: 1 });
      const end = endOfWeek(new Date(date), { weekStartsOn: 1 });
      return new DateRange<D>(start as any, end as any);
    }

    return new DateRange<D>(null, null);
  }
}
