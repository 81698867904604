import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { ComeAndGoBoxModule } from '@app/components/come-and-go-box/come-and-go-box.module';
import { DayPickerPopoverModule } from '@app/components/day-picker-popover/day-picker-popover.module';
import { FrillButtonModule } from '@app/components/frill-button/frill-button.module';
import { ShellMenuToggleModule } from '@app/components/shell-menu-toggle/shell-menu-toggle.module';
import { CoreModule } from '@app/core';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared';
import { TgToolbarModule } from '@app/shared/dialogs/tg-toolbar/tg-toolbar.module';
import { RecordToolbarModule } from '@app/shared/record-toolbar/record-toolbar.module';
import { TimeComegoViewModule } from '@app/shared/time-comego-view/time-comego-view.module';
import { TimeRangePickerModule } from '@app/shared/time-range-picker/time-range-picker.module';
import { TimeTrackerCalendarModule } from '@app/shared/time-tracker-calendar/time-tracker-calendar.module';
import { TimeTrackerTableModule } from '@app/shared/time-tracker-table/time-tracker-table.module';
import { UserAvatarModule } from '@app/shared/user-avatar/user-avatar.module';
import { UserMenuModule } from '@app/shared/user-menu/user-menu.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DateFnsModule } from 'ngx-date-fns';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HomeMobileControlsComponent } from './home-mobile-controls/home-mobile-controls.component';
import { HomeRoutingModule } from './home-routing.module';
import { HomeTimerComponent } from './home-timer/home-timer.component';
import { HomeComponent } from './home.component';
import { HomeService } from './home.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    ReactiveFormsModule,
    SharedModule,

    MaterialModule,
    HomeRoutingModule,
    UserMenuModule,
    RecordToolbarModule,
    AngularSvgIconModule,
    InfiniteScrollModule,
    TimeTrackerTableModule,
    TimeTrackerCalendarModule,
    TimeRangePickerModule,
    UserAvatarModule,
    DateFnsModule,
    CustomDirectivesModule,
    NgxSkeletonLoaderModule,
    ScrollingModule,
    ShellMenuToggleModule,
    FrillButtonModule,
    ExperimentalScrollingModule,
    DayPickerPopoverModule,
    ComeAndGoBoxModule,
    TimeComegoViewModule,
    TgToolbarModule,
  ],
  declarations: [HomeComponent, HomeMobileControlsComponent, HomeTimerComponent],
  providers: [],
})
export class HomeModule {
  static forRoot(): ModuleWithProviders<HomeModule> {
    return {
      ngModule: HomeModule,
      providers: [HomeService],
    };
  }
}
