import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Logger as AppLogger } from '@app/_helpers/logger';
import { AppModule } from '@app/app.module';
import { Query, QueryEntity, __stores__, akitaDevtools, enableAkitaProdMode } from '@datorama/akita';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular-ivy';
import mainExtended from 'main-extended';
import { AkitaPersistState } from 'main.store';
import { Logger } from 'timeghost-api';
if (environment.enableSentry) {
  Sentry.init({
    dsn: 'https://931dbdf7455bce36b1a492e2a4067fcd@error.timeghost.io/3',
    debug: !environment.production,
    ignoreErrors: [
      'ExpressionChangedAfterItHasBeenCheckedError',
      'InteractionRequiredAuthError',
      `NetworkError: Failed to execute 'send' on 'XMLHttpRequest'`,
      `BrowserAuthError: user_cancelled: User cancelled the flow.`,
      /Non-Error exception captured with keys/,
      /(\w+)-extension[://]/,
      /(.*)graph.microsoft.com\/v1.0\/users\/(.*)\/photos\//,
      /\.(jp(e)?g|png|gif)$/,
      /fwcrm is not defined/,
      /Cannot start a HubConnection/,
      /executing a cancelled action/,
      /^macroTask/,
    ],
    release: environment.version,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: environment.production
          ? [environment.clientUrl]
          : ['localhost', 'localhost:4200', environment.clientUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
      new Sentry.Integrations.FunctionToString(),
      new Sentry.Integrations.HttpContext(),
      new Sentry.Integrations.Dedupe(),
    ],
    tracesSampleRate: environment.production ? 1 : 0,
  });
  Sentry.setTags({
    environment: environment.production ? 'production' : 'dev',
    release: environment.releaseName || 'dev',
    'git-version': environment.version,
  });
}
if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
  Logger.enableProductionMode();
  AppLogger.enableProductionMode();
} else {
  akitaDevtools();
}
(function () {
  mainExtended();
  platformBrowserDynamic([AkitaPersistState]) // required to inject persistState into child classes, sets before app module init
    .bootstrapModule(AppModule)
    .then(() => {
      if (!environment.production)
        window.$$akita = {
          stores: __stores__,
          queries: Object.entries(__stores__).reduce((acc, [name, store]) => {
            if (!acc[name]) acc[name] = 'entityActions' in store ? new QueryEntity(store as any) : new Query(store);
            return acc;
          }, {}),
        };
    })
    .catch((err) => console.log(err));
})();
