import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { TagPickerDialogModule } from '@app/shared/dialogs/tag-picker-dialog/tag-picker-dialog.module';
import { TimeInputModule } from '@app/shared/time-input/time-input.module';
import { UserAvatarModule } from '@app/shared/user-avatar/user-avatar.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserSinglePickerDialogModule } from '../user-single-picker-dialog/user-single-picker-dialog.module';
import { ComeAndGoCreateDialogComponent } from './come-and-go-create-dialog.component';
import { EmployeeFilterPopoverModule } from '../employee-filter-popover/employee-filter-popover.module';
import { TimeRangePickerModule } from '@app/shared/time-range-picker/time-range-picker.module';

@NgModule({
  declarations: [ComeAndGoCreateDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    UserAvatarModule,
    UserSinglePickerDialogModule,
    TimeInputModule,
    TagPickerDialogModule,
    EmployeeFilterPopoverModule,
    TimeRangePickerModule,
  ],
})
export class ComeAndGoCreateDialogModule {}
